$green: #08a908;
$red: #ff6961;
$orange: #ff9a47;
$yellow: #fce22a;
$blue: #31c9c6;
$purple: #8872c5;

.green {
  background: $green;
}

.greenColor {
  color: $green;
}

.yellow {
  background: $yellow;
}

.orange {
  background: $orange;
}

.purple {
  background: $purple;
}

.orangeColor {
  color: $orange;
}

.red {
  background: $red;
}

.redColor {
  color: $red !important;
}

.blue {
  background: $blue;
}
