body {
  --line-yellow: url(../assets/marker/underline.svg);
}

.underline {
  color: inherit;
  background: var(--line-yellow) bottom left / 110% 0.4em no-repeat;
  text-decoration: none;
  padding-bottom: 0.2em;
}
