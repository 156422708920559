@use "@angular/material" as mat;

@include mat.core();

$angular-material-3-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$red-palette
    ),
    density: (
      scale: 0
    )
  )
);

:root {
  @include mat.all-component-themes($angular-material-3-theme);
}
