@import "flex.scss";
@import "assets/font/font.scss";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~leaflet/dist/leaflet.css";
@import "~jsoneditor/dist/jsoneditor.min.css";
@import "../node_modules/swiper/swiper-bundle.min.css";
@import "styles/marker.scss";
@import "styles/underline.scss";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

html,
body {
  height: 100%;

  #crisp-chatbox {
    z-index: 999;
  }

  .mat-mdc-card {
    padding: 10px;
    box-shadow: none;
    border-width: 1px;
    border-color: #dbdbdb;
  }
}

* {
  box-sizing: border-box;
}
.question-mark-tooltip {
  margin: 0 10px;
  background-color: #005cbb;
  color: #ffffff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
}

.help-icon {
  font-size: 22px !important;
  height: 22px !important;
  width: 18px !important;
  color: #005cbb;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.visibilityHidden {
  visibility: hidden;
}

.mat-mdc-tooltip-panel {
  pointer-events: none !important;
}

.mat-mdc-dialog-surface {
  background: #fafafa !important;
}

input::placeholder {
  opacity: 1 !important;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body.edge-browser {
  font-family: sans-serif;
}

.cdk-overlay-pane {
  max-height: 100% !important;
}
.hint-error {
  color: red;
}
.mat-mdc-form-field-subscript-wrapper {
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }
}

.mat-mdc-option-active:not(:hover) {
  background-color: transparent !important;
}

.mat-mdc-dialog-container {
  //FIX BUG ON SMALL SCREENS Dialog not listen to max-height 100%
  height: auto !important;
}

.pointerEventsNone {
  pointer-events: none;
}

.disabled {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}

mat-card-actions.mat-mdc-card-actions,
.mat-mdc-dialog-actions {
  justify-content: end !important;
}

mat-card-header.mat-mdc-card-header {
  padding: 16px 16px 8px;
}

.mat-drawer-content {
  overflow: unset !important;
}

.main-content-container {
  max-width: 800px;
  width: 100%;
  padding: 16px;
}

.mat-mdc-card-subtitle {
  align-items: center;
  display: flex !important;
}

.mat-mdc-tab-body-wrapper {
  padding-top: 16px;
}

.mat-mdc-dialog-content {
  //padding-top: 16px !important;
  max-height: none !important;
  .main-content-container {
    padding: 0;
  }
}

.flex-2 {
  overflow: auto;
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  @media (max-width: 800px) {
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sub-header {
    width: 100%;
  }
}

.mat-mdc-mini-fab {
  margin: 8px;
}

.mdc-checkbox {
  margin: 4px 0 !important;
}

.mat-mdc-slide-toggle {
  margin: 16px 0;

  .mdc-switch {
    margin-inline-end: 8px;
  }
}

.mat-mdc-progress-spinner {
  display: flex;
  justify-self: center;
  align-self: center;
}

.mat-mdc-progress-bar {
  top: 50%;
  position: absolute !important;
  width: 80% !important;
  justify-self: center;
}

.cdk-overlay-backdrop {
  background: #4b4b4b7a;
}

.visibilityHidden {
  visibility: hidden;
}

.displayNone {
  display: none !important;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-card-actions,
.mat-mdc-dialog-actions {
  gap: 8px;
  align-items: stretch !important;
}

.mat-mdc-button {
  height: auto !important;
  min-height: 36px;
  padding: 4px 16px !important;
  .mat-mdc-button-touch-target {
    max-height: 100%;
  }
}

.success-snackbar > * {
  background: #004d1c;
}
.enabled {
  color: #4ec177;
}
.error {
  color: #c14e4e;
}

.hasSettings {
  background: #ff4081;
}

.timepicker-backdrop-overlay {
  z-index: 1999 !important;
}

.timepicker-overlay {
  z-index: 2000 !important;
}
